import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMembersList() {
  // Use toast
  const toast = useToast()

  const refMemberListTable = ref(null)
  const statusOptions = [
    { value: '', text: 'All' },
    { value: 'Activate', text: 'Activate' },
    { value: 'Inactivate', text: 'Inactivate' },
  ]

  // Table Handlers
  const tableColumns = [
    { key: 'hamburger', label: '', class: 'text-center' },
    { key: 'position', label: 'id', class: 'text-center' },
    { key: 'image', class: 'text-center' },
    { key: 'fullname', label: 'member name' },
    { key: 'status' },
    { key: 'lastUpdated' },
    { key: 'actions', class: 'text-center' },
  ]
  const perPage = ref(10)
  const totalMembers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const statusQuery = ref('')
  const sortBy = ref('position')
  const isSortDirDesc = ref(false)
  const draggableOptions = ref({
    animation: 400,
  })
  const dataMeta = computed(() => {
    const localItemsCount = refMemberListTable.value ? refMemberListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMembers.value,
    }
  })

  const refetchData = () => {
    refMemberListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusQuery], () => {
    refetchData()
  })

  const fetchMembers = (_, callback) => {
    store
      .dispatch('app-member/fetchMembers', {
        search: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sort: sortBy.value,
        ascend: !isSortDirDesc.value,
        status: statusQuery.value
      })
      .then(response => {
        const { data, total } = response.data
        callback(data)
        totalMembers.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching members list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteMember = async (id) => {
    try {
      const response = await store.dispatch('app-member/deleteMember', id);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error delete member',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const swapPosition = (oldPosition, newPosition) => {
    return store.dispatch('app-member/swapPosition', { oldPosition, newPosition })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveMemberStatusVariant = status => {
    if (status === 'Activate') return 'success'
    if (status === 'Inactivate') return 'secondary'
    return 'primary'
  }

  return {
    // method
    swapPosition,
    fetchMembers,
    deleteMember,

    // data
    tableColumns,
    perPage,
    currentPage,
    totalMembers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMemberListTable,
    draggableOptions,

    // status
    statusOptions,
    statusQuery,
    
    // UI
    resolveMemberStatusVariant,
    refetchData,
  }
}
