<template>
  <div>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Search -->
        <b-col sm="12">
          <b-row>
            <div class="d-flex justify-content-between gap-2 w-full filter">
              <div class="d-flex gap-2 w-full flex-wrap max-w-800px">
                <div class="d-flex align-items-center flex-grow">
                  <p class="search-label">Search</p>
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block"
                    placeholder="Search by keyword"
                  />
                </div>
                <div class="d-flex align-items-center flex-grow">
                  <p class="search-label">Status</p>
                  <b-form-select
                    v-model="statusQuery"
                    :options="statusOptions"
                  />
                </div>
              </div>
              <b-button
                variant="primary"
                :to="{ name: 'member-create' }"
              >
                <span class="text-nowrap">Add Member</span>
                <feather-icon
                  icon="PlusCircleIcon"
                  size="16"
                  class="add-member-icon"
                />
              </b-button>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refMemberListTable"
        class="position-relative mb-0"
        responsive
        show-empty
        primary-key="id"
        empty-text="No matching records found"
        :busy.sync="isBusy"
        :items="fetchMembers"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        v-draggabletable="draggableOptions"
      >
        <!-- Column: User -->
        <template #cell(hamburger)>
          <feather-icon
            icon="MenuIcon"
            size="24"
          />
        </template>

        <!-- Column: Member -->
        <template #cell(image)="data">
          <b-avatar
            size="32"
            :src="data.item.memberImg"
          />
        </template>

        <!-- Column: fullname -->
        <template #cell(fullname)="data">
          <span>{{ `${data.item.content.english.firstname} ${data.item.content.english.lastname}` }}</span>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveMemberStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(lastUpdated)="data">
          <span>{{ data.item.createdTime }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <div class="action-button">
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </div>
              </template>
              <b-dropdown-item :to="{ name: 'member-edit', params: { id: data.item.id } }">
                <span class="align-middle ml-50">View &amp; Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="confirmDelete(data.item.id, `${data.item.content.english.firstname} ${data.item.content.english.lastname}`)"
              >
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>
    </b-card>

    <!-- Pagination -->
    <div class="mt-2 mx-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing
            </span>
            <b-form-select
              v-model="perPage"
              :options="perPageOptions"
              class="mx-1"
            />
            <span class="text-nowrap"> of {{ dataMeta.of }} entries </span>
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalMembers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownDivider, BDropdownItem, BFormSelect, BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useMembersList from './useMembersList'
import memberStoreModule from '../teamStoreModule'
import { draggabletable } from "@/libs/vue-draggable.js";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormSelect,
    BPagination,

    vSelect,
  },
  data() {
    return {
      isBusy: false,
    }
  },
  directives: { draggabletable },
  methods: {
    confirmDelete(id, fullName) {
      this.$swal({
        title: 'Are you sure to delete account?',
        text: 'This will permanently erase your account. You can’t undo action.',
        icon: 'warning',
        iconColor: '#FF9F43',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          htmlContainer: 'my-50',
          actions: 'flex-row-reverse my-1',
          confirmButton: 'btn btn-danger px-5',
          cancelButton: 'btn btn-outline-secondary px-5 mr-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (!result.value) return

        const { status } = await this.deleteMember(id);
        if (status !== 200) {
          this.makeToast('Delete failed!', `Can not delete member.`);
          return;
        }

        setTimeout(() => this.$router.go(0), 300);
        this.makeToast(
          'Delete Succeeded!',
          `Member has been deleted “${fullName}”.`,
        );
      })
    },
    changePositionToast() {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class="text-danger"
              icon="XCircleIcon"
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class="ml-1 text-danger">Change position failed</h4>
              <div class="ml-1 font-14px">Can not change position</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
    makeToast(title, message) {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class="text-success"
              icon="CheckCircleIcon"
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class="ml-1 text-success">{title}</h4>
              <div class="ml-1 font-14px">{message}</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-member'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, memberStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      swapPosition,
      fetchMembers,
      deleteMember,

      tableColumns,
      perPage,
      currentPage,
      totalMembers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMemberListTable,
      refetchData,

      // status
      statusOptions,
      statusQuery,

      // UI
      resolveMemberStatusVariant,
      
      draggableOptions,
    } = useMembersList()

    return {
      // method
      swapPosition,
      fetchMembers,
      deleteMember,

      // data
      tableColumns,
      perPage,
      currentPage,
      totalMembers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMemberListTable,
      refetchData,

      // status
      statusOptions,
      statusQuery,

      // Filter
      avatarText,

      // UI
      resolveMemberStatusVariant,
      
      draggableOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.search-label {
  margin: 0 10px 0 0;
}
.add-member-icon {
  margin-left: 8px;
}
.action-button {
  border: 1px solid #6e6b7b;
  border-radius: 50%;
  padding: 5px;
}
.max-w-800px {
  max-width: 800px;
}
.w-150px {
  width: 150px
}
.gap-2 {
  gap: 8px
}
@media (max-width: 768px) {
  .filter {
    flex-direction: column;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
